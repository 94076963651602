import { create, set } from "lodash";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmKeyLocationsDataSource } from "./alarm-datasource";
import { createConnectedListView, FilterType, ListView, ListViewColumnType } from "../../UI/view/list-view";
import { FormItemType } from "../../UI/view/component-map";
import { craneSVG, peopleSVG, plantSVG, WorlMapListComponent } from "./alarm-worldmap";
import { on } from "events";
import { watch } from "@arcgis/core/core/reactiveUtils";

export const typeDisplay = (type) => {
  switch (type) {
    case 'LiquidCustomer':
      return 'Liquid Customer';
    case 'GasCustomer':
      return 'Gas Customer';
    case 'Port':
      return 'Port';
    case 'Plant':
      return 'Plant';
    case 'KeyLocation':
      return 'Key Location';
    default:
      return type;
  }
}

export const updateKeyLocationsView = {
  editMode: true,
  items: [
    {
      name: 'keyLocationType',
      label: 'Type',
      defaultValue: 'KeyLocation',
      hideIf: () => true,
      required: true
    },
    {
      name: 'display',
      label: 'Name'
    },
    {
      name: 'latitude',
      label: 'Latitude',
      onChange: (value, getValues, setValue, editTableProps, formState) => {
        const longitude = getValues('longitude');
        setValue('mapCoordinates', { latitude: value, longitude });
      }
    },
    {
      name: 'longitude',
      label: 'Longitude',
      onChange: (value, getValues, setValue, editTableProps, formState) => {
        const latitude = getValues('latitude');
        setValue('mapCoordinates', { longitude: value, latitude });
      }
    },
    {
      type: FormItemType.worldmap,
      name: 'mapCoordinates',
      componentArgs: {
        geometryRenderer: (datum) => ({
          type: 'point'
        }),
        singleSelectionMode: true,
        showSearchbox: true,
/*         getPointCoordinates: (getValues) => {
          const latitude = getValues('latitude');
          const longitude = getValues('longitude');
          return { latitude, longitude };
        }, */
        onMapClick: (e, setValue) => {
          const { latitude, longitude } = e.mapPoint;
          setValue('latitude', latitude);
          setValue('longitude', longitude);
        },
  /*       listComponent: WorlMapListComponent, */
        detailsEndpoint: null,
        detailsLink: null,
        /*   filterField: 'assetType', */
        /*         filters: [
                  {
                    type: 'Liquid', color: [0, 85, 145], legendColor: 'primary', display: 'Liquid',
                  },
                  {
                    type: 'Gas', color: '#28a745', legendColor: 'success', display: 'Gas',
                  },
                  {
                    type: 'Chassis', color: 'red', legendColor: 'danger', display: 'Chassis',
                  },
                  {
                    type: 'Plant', display: 'Plant', icon: plantSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2, paddingLeft: 2 }
                  },
                  {
                    type: 'Customer', display: 'Customer', icon: peopleSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
                  },
                  {
                    type: 'Port', display: 'Port', icon: craneSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
                  },
                ],
                titleRenderer: (datum) => {
                  return `${datum.latitude} ${datum.longitude} [${datum.assetType}]`;
                }, */

      }
    }
  ]

};

export const keyLocationsListView = {
  update: updateKeyLocationsView,
  create: updateKeyLocationsView,
  filters: [
    {
      type: FilterType.multiselect,
      fieldName: 'keyLocationType',
      filterValue: { ['Liquid Customer']: true, ['Gas Customer']: true, ['Port']: true, ['Plant']: true, ['Key Location']: true },
      options: () => ['Liquid Customer', 'Gas Customer', 'Port', 'Plant', 'Key Location'],
/*       labels: { ['LiquidCustomer']: 'Liquid Customer', ['GasCustomer']: 'Gas Customer', ['Port']: 'Port', ['Plant']: 'Plant', ['KeyLocation']: 'Key Location' }, */
    },
  ],
  columns: [
    {
      type: ListViewColumnType.edit,
      accessor: 'editcolumn'
    },
    {
      id: 'keyLocationType',
      accessor: (row) => {
        return typeDisplay(row.keyLocationType)
      },
      Header: 'Type',
      defaultSort: true
    },
    {
      accessor: 'display',
      Header: 'Name'
    },
    {
      accessor: 'latitude',
      Header: 'Latitude'
    },
    {
      accessor: 'longitude',
      Header: 'Longitude'
    }
  ]
} as ListView;

export const KeyLocationsView = createConnectedListView(keyLocationsListView, alarmKeyLocationsDataSource);