/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, InputGroup,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BsLockFill, BsPencilFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { convertCurrency } from '../../../services/currency';
import { formatCurrency } from '../../../services/format';
import { getPurchaseRequestCost } from '../../../services/requests';
import { getDisplayNameByOID } from '../../../services/table-helpers';
import { selectIsMaintenanceManager } from '../../../store/slices/auth-slice';
import { selectMaintenanceRequestDropdownObjects } from '../../../store/slices/maintenance-requests-slice';
import {
  fetchPurchaseOrderPartsPurchased,
  selectCurrentPurchaseOrder,
  selectCurrentPurchaseOrderId,
  selectCurrentPurchaseOrderPartDeliveriesByPurchaseRequestId,
  setLogShipmentStatus,
  updatePurchaseOrderStatus,
} from '../../../store/slices/purchase-orders-slice';
import {
  fetchPurchaseRequestsByPurchaseOrder, selectPurchaseRequestsByCurrentPurchaseOrder,
  setpurchaseRequestsByPurchaseOrderFetch,
  updatePurchaseRequestStatus,
} from '../../../store/slices/purchase-request-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';
import RowSelectTable from '../../UI/organisms/Table/row-select-table';
import PurchaseRequestForm from '../Part/purchase-request-form';
import { purchaseRequestPriorities } from '../Part/purchase-requests-values';
import LogShipmentsModal from './log-shipments-modal';

function PurchaseOrderPurchaseRequests(props) {
  const [prCost, setPRCost] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [editPurchaseRequest, setEditPurchaseRequest] = useState(null);

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [logShipmentModalShow, setLogShipmentModalShow] = useState(false);

  const tableInstance = useRef(null);
  const usersByOid = useSelector(selectUsersByOid);
  const currencyRatesData = useSelector((x) => x.currencyRates.data);
  const currencyRates = useMemo(() => Object.fromEntries(currencyRatesData.map((x) => [x.currency, x.rate])), [currencyRatesData]);
  const purchaseRequestsOnPurchaseOrderData = useSelector(selectPurchaseRequestsByCurrentPurchaseOrder);
  const currentPurchaseOrder = useSelector(selectCurrentPurchaseOrder);
  const currentPurchaseOrderStatusId = currentPurchaseOrder?.purchaseOrderStatusId;
  const purchaseOrdersUpdateStatus = useSelector((state) => state.purchaseOrder.purchaseOrderUpdate.status);
  const purchaseRequestsByPurchaseOrderFetchStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsByPurchaseOrderFetch.status);
  const purchaseRequestsByPurchaseOrderFetchError = useSelector((state) => state.purchaseRequests.purchaseRequestsByPurchaseOrderFetch.error);
  const purchaseRequestsDeleteStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsDelete.status);
  const purchaseRequestsDeleteError = useSelector((state) => state.purchaseRequests.purchaseRequestsDelete.error);
  const logShipmentsDeleteError = useSelector((state) => state.purchaseOrder.logShipment.error);

  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);
  const { purchaseRequestStatuses, purchaseOrderStatuses } = useSelector(selectMaintenanceRequestDropdownObjects);

  const currentDeliveriesByPurchaseRequestId = useSelector(selectCurrentPurchaseOrderPartDeliveriesByPurchaseRequestId);

  const refreshPurchaseRequestCosts = async () => {
    const prIds = purchaseRequestsOnPurchaseOrderData.map((pr) => pr.purchaseRequestId);
    const costs = await getPurchaseRequestCost(prIds);
    setPRCost(costs);
  };
  React.useEffect(() => {
    if (purchaseRequestsOnPurchaseOrderData?.length) {
      if (currentPurchaseOrder?.isStockTransferOrder) {
        refreshPurchaseRequestCosts();
      }
    }
  }, [purchaseRequestsOnPurchaseOrderData]);

  const { confirm } = useConfirm();

  const handleModalShow = (purchaseRequest) => {
    setEditPurchaseRequest(purchaseRequest);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setEditPurchaseRequest(null);
    setModalShow(false);
  };

  const dispatch = useDispatch();

  const currentPurchaseOrderId = useSelector(selectCurrentPurchaseOrderId);

  const loadData = useCallback(async () => {
    if (purchaseRequestsByPurchaseOrderFetchStatus === 'idle' || purchaseRequestsByPurchaseOrderFetchStatus === 'failed' || purchaseOrdersUpdateStatus === 'succeeded') {
      dispatch(fetchPurchaseRequestsByPurchaseOrder(props.purchaseOrderId));
    }
  }, [purchaseRequestsByPurchaseOrderFetchStatus, purchaseOrdersUpdateStatus, props.purchaseOrderId, dispatch]);

  const logShipmentRequestStatus = useSelector(
    (state) => state.purchaseOrder.logShipment.status,
  );

  useEffect(() => {
    if (purchaseRequestsByPurchaseOrderFetchStatus === 'succeeded') {
      dispatch(setpurchaseRequestsByPurchaseOrderFetch('idle'));
    } else if (purchaseRequestsByPurchaseOrderFetchStatus === 'failed') {
      dispatch(setpurchaseRequestsByPurchaseOrderFetch('idle'));
    }
  }, [purchaseRequestsByPurchaseOrderFetchStatus, currentPurchaseOrderId]);

  useEffect(() => {
    if (logShipmentRequestStatus === 'succeeded') {
      toast.success('Successfully Created MRN');
      dispatch(setLogShipmentStatus('idle'));
      dispatch(fetchPurchaseOrderPartsPurchased(currentPurchaseOrderId));
    } else if (logShipmentRequestStatus === 'failed') {
      toast.error(`Failed to Create MRN\n${logShipmentsDeleteError}`);
      dispatch(setLogShipmentStatus('idle'));
    }
  }, [logShipmentRequestStatus, currentPurchaseOrderId]);

  // Request status listener
  useEffect(() => {
    if (purchaseRequestsDeleteStatus === 'succeeded') {
      toast.success('Purchase Request Deleted');
    } else if (purchaseRequestsDeleteStatus === 'failed') {
      toast.error(`Failed to Delete Purchase Request\n${purchaseRequestsDeleteError}`);
    }
  }, [purchaseRequestsDeleteStatus]);

  useEffect(() => {
    Object.keys(purchaseRequestsOnPurchaseOrderData).forEach((key) => {
      const purchaseRequestStatus = purchaseRequestStatuses[purchaseRequestsOnPurchaseOrderData[key].purchaseRequestStatusId] ?? '';
      const { quantityRequested } = purchaseRequestsOnPurchaseOrderData[key];
      const quantityDelivered = (currentDeliveriesByPurchaseRequestId[purchaseRequestsOnPurchaseOrderData[key].purchaseRequestId]?.reduce((acc, delivery) => {
        return acc + (delivery.quantityAccepted ?? 0);
      }, 0) ?? 0);
      if (purchaseRequestStatus.name === 'On PO' && quantityRequested <= quantityDelivered) {
        dispatch(updatePurchaseRequestStatus({
          purchaseRequestId: purchaseRequestsOnPurchaseOrderData[key].purchaseRequestId,
          purchaseRequestStatusId: 1,
        })).catch((error) => {
          console.log(error);
        });
      }
    });
  }, [purchaseRequestsOnPurchaseOrderData, purchaseRequestStatuses, dispatch]);

  useEffect(() => {
    let isAllPurchaseRequestStatusesClosed = true;
    const currentPurchaseOrderStatus = purchaseOrderStatuses[currentPurchaseOrderStatusId] ?? '';
    Object.keys(purchaseRequestsOnPurchaseOrderData).forEach((key) => {
      const purchaseRequestStatus = purchaseRequestStatuses[purchaseRequestsOnPurchaseOrderData[key].purchaseRequestStatusId] ?? '';
      if (purchaseRequestStatus.name !== 'Closed') {
        isAllPurchaseRequestStatusesClosed = false;
      }
    });
    if (isAllPurchaseRequestStatusesClosed && currentPurchaseOrderStatus.name === 'Received'
      && purchaseRequestsOnPurchaseOrderData.length > 0 && currentPurchaseOrder.shipments.length > 0) {
      dispatch(updatePurchaseOrderStatus({
        purchaseOrderId: currentPurchaseOrderId,
        purchaseOrderStatusId: 6,
      }));
    }
  }, [currentPurchaseOrderStatusId, purchaseOrderStatuses, purchaseRequestsOnPurchaseOrderData, purchaseRequestStatuses, dispatch]);

  useEffect(() => {
    loadData();
  }, [purchaseRequestsOnPurchaseOrderData]);

  const getDisplayName = useCallback((OID) => {
    return getDisplayNameByOID(OID, usersByOid);
  }, [usersByOid]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => {
          const defaultActionButtons = () => {
            return (
              <div className="d-flex">
                <Button
                  size="xsm"
                  title="Edit"
                  onClick={() => {
                    handleModalShow(cell.row.original);
                  }}
                >
                  <BsPencilFill />
                </Button>
                {/* &nbsp;
                <Button
                  size="xsm"
                  variant={cell.row.original.isActive ? 'danger' : 'success'}
                  title={cell.row.original.isActive ? 'Deactivate' : 'Activate'}
                  onClick={async () => {
                    const isConfirmed = await confirm('Are you sure you want to delete this Purchase Request?');
                    if (isConfirmed) {
                      dispatch(deletePurchaseRequest(cell.row.original.purchaseRequestId));
                    }
                  }}
                >
                  {cell.row.original.isActive ? <BsXLg /> : <BsPlusLg />}
                </Button> */}
              </div>
            );
          };
          const purchaseRequestCloseButton = () => {
            const status = purchaseRequestStatuses[cell.row.original.purchaseRequestStatusId] ?? '';
            if (status.name === 'On PO' && isMaintenanceManager) {
              return (
                <div className="d-flex">
                  <Button
                    size="xsm"
                    variant="danger"
                    title="Close"
                    disabled={!cell.row.original.isActive}
                    onClick={async () => {
                      const isConfirmed = await confirm('Are you sure you want to close this Purchase Request?');
                      if (isConfirmed) {
                        await dispatch(updatePurchaseRequestStatus({
                          purchaseRequestId: cell.row.original.purchaseRequestId,
                          purchaseRequestStatusId: 1,
                        })).unwrap()
                          .then((originalPromiseResult) => {
                            toast.success('Successfully Closed Purchase Request');
                          }).catch((error) => {
                            console.log(error);
                            toast.error('Failed to Close Purchase Request');
                          });
                      }
                    }}
                  >
                    <BsLockFill />
                  </Button>
                </div>
              );
            }
            return '';
          };
          return (
            <div className="d-flex">
              {defaultActionButtons()}
              &nbsp;
              {purchaseRequestCloseButton()}
            </div>
          );
        },
      },
      {
        Header: 'ID',
        accessor: 'purchaseRequestId',
        disableSortBy: true,
      },
      {
        Header: 'Part Name',
        accessor: 'part.partName',
        Cell: ({ value, row }) => (
          <NavLink
            to={`/maintenance/parts-inventory/${row.original.part.partId}`}
          >
            {value}
          </NavLink>
        ),
      },
      {
        Header: 'Cost',
        accessor: 'newCost',
        Cell: ({ value, row }) => {
          if (currentPurchaseOrder.isStockTransferOrder) {
            if (prCost && prCost[row.original.purchaseRequestId]) {
              return formatCurrency(convertCurrency(prCost[row.original.purchaseRequestId], row.original.currency, currencyRates));
            }
            return null;
          }
          return formatCurrency(convertCurrency(value, row.original.currency, currencyRates));
        },
      },
      {
        Header: 'Currency',
        id: 'currency',
        accessor: ({ currency }) => currency ?? 'USD',
      },
      {
        Header: 'Status',
        accessor: 'purchaseRequestStatusId',
        Cell: ({ value }) => {
          return (value == null ? null : (purchaseRequestStatuses[value]?.name ?? null));
        },
      },
      {
        Header: 'Quantity Requested',
        accessor: 'quantityRequested',
      },
      {
        Header: 'Quantity Delivered',
        id: 'quantityDelivered',
        accessor: 'purchaseRequestId',
        Cell: ({ value }) => {
          return (currentDeliveriesByPurchaseRequestId[value]?.reduce((acc, delivery) => {
            return acc + (delivery.quantityAccepted ?? 0);
          }, 0) ?? 0);
        },
      },
      {
        Header: 'Requested By',
        id: 'createdBy',
        accessor: ({ createdBy: value }) => (value == null ? null : getDisplayName(value)),
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ value }) => (value == null ? null : purchaseRequestPriorities[value]),
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [
      purchaseRequestStatuses,
      currentDeliveriesByPurchaseRequestId,
      purchaseRequestPriorities,
      prCost,
      currencyRates,
    ],
  );

  const memoPRonPOData = useMemo(
    () => {
      return [...purchaseRequestsOnPurchaseOrderData.map((request) => ({
        ...request,
        // eslint-disable-next-line no-nested-ternary
        disabled: currentPurchaseOrder.isStockTransferOrder ? '' : request.newCost ? '' : 'Cost must be added to Purchase Request before shipment can be logged',
      }))];
    },
    [purchaseRequestsOnPurchaseOrderData],
  );

  const requestsAreLoading = [
    purchaseRequestsByPurchaseOrderFetchStatus,
  ].includes('loading');

  const logShipmentDisabled = currentPurchaseOrder?.isStockTransferOrder ? (
    currentPurchaseOrder?.purchaseOrderStatusId !== 3
  ) : (selectedRequests.length < 1
    || currentPurchaseOrder?.purchaseOrderStatusId < 2
    || currentPurchaseOrder?.purchaseOrderStatusId > 4);

  return (
    <>
      <div className="card-tools">

        <InputGroup size="sm">
          <TableSearchFilter tableInstance={tableInstance} />
          &nbsp;
          <Button
            variant="primary"
            size="sm"
            disabled={logShipmentDisabled}
            onClick={() => {
              setLogShipmentModalShow(true);
            }}
          >
            Create MRN
          </Button>
        </InputGroup>
      </div>
      <RowSelectTable
        columns={columns}
        data={memoPRonPOData}
        ref={tableInstance}
        onRowSelect={(rows) => setSelectedRequests(rows)}
        rowProps={() => ({})}
      />
      {purchaseRequestsByPurchaseOrderFetchStatus === 'failed' && <ErrorContent errorMessage={purchaseRequestsByPurchaseOrderFetchError} />}
      {(requestsAreLoading || props.purchaseOrderStatusIsLoading) && <LoadingContent />}
      <PurchaseRequestForm
        show={modalShow}
        onHide={handleModalClose}
        partId={editPurchaseRequest?.partId}
        purchaseRequest={editPurchaseRequest}
      />
      <LogShipmentsModal
        show={logShipmentModalShow}
        onHide={() => setLogShipmentModalShow(false)}
        selectedRequests={selectedRequests}
        logShipmentRequestStatus={logShipmentRequestStatus}
      />
    </>
  );
}
export default PurchaseOrderPurchaseRequests;
